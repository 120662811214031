<template>
  <div id="dashboard" class="dashboard-container">
    <ul class="nav nav-pills nav-fill mb-1 mt-2">
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/nokkeltall', query: { from_date: fromDate, to_date: toDate, stations: selectedStations } }"><i
            class="fas fa-chart-bar" aria-hidden="true"></i> {{ translations[selectedLanguage]['dashboard']
            }}</router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/innsikt', query: { from_date: fromDate, to_date: toDate, stations: selectedStations } }"><i
            class="fas fa-lightbulb" aria-hidden="true"></i> {{ translations[selectedLanguage]['insights']
            }}</router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/meteorer', query: { from_date: fromDate, to_date: toDate, stations: selectedStations } }"><i
            class="fas fa-search" aria-hidden="true"></i> {{ translations[selectedLanguage]['meteors'] }}</router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/meteorkart', query: { from_date: fromDate, to_date: toDate, stations: selectedStations } }"><i
            class="fas fa-map-marked-alt" aria-hidden="true"></i> {{ translations[selectedLanguage]['meteorMap']
            }}</router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/kameranettverk' }"><i class="fas fa-chart-bar" aria-hidden="true"></i>
          {{ translations[selectedLanguage]['cameraNetwork'] }}</router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link py-1 custom-link" active-class="custom-active-link"
          :to="{ path: '/statistikk/dashboard/datanedlasting', query: { from_date: fromDate, to_date: toDate, stations: selectedStations } }"><i
            class="fas fa-download" aria-hidden="true"></i> {{ translations[selectedLanguage]['dataDownload']
            }}</router-link>
      </li>
    </ul>

    <!-- Show buttons for changing language -->
    <!-- div class="d-flex justify-content-end">
      <button v-for="(lang, code) in translations" :key="code" class="btn btn-sm me-2 text-white"
        :style="selectedLanguage === code ? 'background-color: #46368e' : 'background-color: transparent'"
        @click="changeLanguage(code)">
        {{ code.toUpperCase() }}
      </button>
    </div> -->


    <div class="modal-mask" v-if="isModalVisible || isModalLoading">
      <div class="modal-container">
        <div class="modal-content">
          <div v-if="isModalLoading">
            <LoadingSpinner :isLoading="true"></LoadingSpinner>
          </div>
          <div v-else>
            <div v-if="!currentMeteor">
              <div class="alert alert-warning alert-dismissible fade show" role="alert">
                <p>Ikke funnet (meteor id: {{ meteorId }})</p>
              </div>
            </div>
            <div v-else>
              <MeteorTiny :meteor="currentMeteor" :meteorData="currentMeteorData" />
            </div>
          </div>
        </div>
        <button class="btn btn-secondary close-modal-btn" v-if="isModalVisible && !isModalLoading" @click="closeModal">
          Lukk
        </button>
      </div>
    </div>

    <!-- Row for showing filter component -->
    <div class="row" v-if="showFilter">
      <FilterComponent :stations="stations" @filter-applied="applyFilter"></FilterComponent>
    </div>


    <!-- Show loading spinner until dashboard is loaded -->
    <div v-if="loading">
      <LoadingSpinner :isLoading="loading"></LoadingSpinner>
    </div>

    <!-- Show visualization if loaded -->
    <div v-else>
      <div class="row d-flex flex-column flex-md-row justify-content-center w-100">
        <div class="col-md-12 me-md-3">
          <!-- Display different visualization components based on current route -->
          <Nokkeltall :coordinates="coordinates" :chartReady="chartReady"
            v-if="$route.path === '/statistikk/dashboard/nokkeltall'" />
          <Innsikt :coordinates="coordinates" v-else-if="$route.path === '/statistikk/dashboard/innsikt'" />
          <Meteorer :coordinates="coordinates" :onMeteorClicked="onMeteorClicked" :openMeteorModal="openMeteorModal"
            v-else-if="$route.path === '/statistikk/dashboard/meteorer'" />
          <Meteorkart :coordinates="coordinates" :openMeteorModal="openMeteorModal" :chartReady="chartReady"
            v-else-if="$route.path === '/statistikk/dashboard/meteorkart'" />
          <Kameranettverk v-else-if="$route.path === '/statistikk/dashboard/kameranettverk'" />
          <Datanedlasting :coordinates="coordinates" v-else-if="$route.path === '/statistikk/dashboard/datanedlasting'" />
          <!-- Show a landingpage when no visualization is selected -->
          <div v-else>
            <div class="col-md-12 justify-content-center p-0 m-0">
              <div class="row p-0  m-0">
                <div class="col-md-4 m-0" v-for="(card, index) in cards" :key="index">
                  <div class="card text-white bg-dark mx-2 my-4">
                    <div class="card-body m-0" style="height:20rem;">
                      <i :class="card.icon" aria-hidden="true"></i>
                      <h5 class="card-title">{{ card.title }}</h5>
                      <h6 class="card-subtitle mb-2">{{ card.subtitle }}</h6>
                      <p class="card-text">{{ card.text }}</p>
                      <div class="text-end text-white"><a href="#" class="text-decoration-none"><i
                            class="fas fa-arrow-right  fa-inverse"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import FilterComponent from '@/components/visualization/FilterComponent.vue';
import MeteorDataService from "@/services/meteor.service";
import MeteorTiny from "@/components/MeteorTiny.vue";
import stations from "@/components/helpers/stations.js";
import LoadingSpinner from '@/components/visualization/LoadingSpinner.vue';
import Nokkeltall from '@/views/Statistikk/Visualiseringer/Nokkeltall.vue';
import Innsikt from '@/views/Statistikk/Visualiseringer/Innsikt.vue';
import Meteorer from '@/views/Statistikk/Visualiseringer/Meteorer.vue';
import Meteorkart from '@/views/Statistikk/Visualiseringer/Meteorkart.vue';
import Kameranettverk from '@/views/Statistikk/Visualiseringer/Kameranettverk.vue';
import Datanedlasting from '@/views/Statistikk/Visualiseringer/Datanedlasting.vue';

/* Import background image from assets */
import BackgroundImage from '@/assets/background-meteor.jpg';


import {
  downloadCSV,
  stripDate,
  generateVideoUrl,
} from "../../components/helpers/helpers.js";

import {
  mapState,
  mapGetters,
  mapActions,

} from "vuex";

/**
 * @name MeteorDashboard
 * @fileOverview A Vue component that displays a Meteor Dashboard page with visualization components and filters.
 * @description
 * This component is responsible for displaying the Meteor Dashboard page with various visualizations
 * related to meteors and camera stations.
 */
export default {
  name: 'MeteorDashboard',

  /**
   * Registering all the sub-components used in the dashboard.
   */
  components: {
    FilterComponent,
    MeteorTiny,
    LoadingSpinner,
    Nokkeltall,
    Innsikt,
    Meteorer,
    Meteorkart,
    Kameranettverk,
    Datanedlasting,
  },

  /**
  * Watchers for detecting changes in the filter values, route changes, and triggering appropriate actions.
  */
  watch: {
    fromDate: 'updateQueryString',
    toDate: 'updateQueryString',
    selectedStations: 'updateQueryString',
    $route: {
      handler: 'onRouteChange',
      immediate: true,
      deep: true,
    },

    isModalVisible(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }




  },

  data() {
    this.stations = stations;
    return {
      // Meteor selected from map or other component
      currentMeteor: null,
      currentMeteorData: null,

      // Response message to user
      message: "",

      // Filter variables
      fromDate: '',
      toDate: '',
      selectedStations: [],

      initialLoad: true,

      // Modal variables for showing selected meteor from map
      isModalVisible: false,
      isModalLoading: false,

      bgImage: BackgroundImage,


      meteorId: null,

      language: this.$store.state.language.selectedLanguage,

      translations: {
        'en': {
          'dashboard': 'Dashboard',
          'keyFigures': 'Key Figures',
          'insights': 'Insights',
          'meteors': 'Meteors',
          'meteorMap': 'Meteor Map',
          'cameraNetwork': 'Camera Network',
          'dataDownload': 'Data Download',

        },
        'no': {
          'dashboard': 'Instrumentbord',
          'keyFigures': 'Nøkkeltall',
          'insights': 'Innsikt',
          'meteors': 'Meteorer',
          'meteorMap': 'Meteorkart',
          'cameraNetwork': 'Kameranettverk',
          'dataDownload': 'Datanedlasting',
        }
      },


      cards: [
        {
          title: "Nøkkeltall for Norsk Meteornettverk",
          subtitle: "Kvantifisering av vårt arbeid",
          text: "Se nøkkeltall om meteorer som er observert av Norsk Meteornettverk. Nøkkeltallene gir en oversikt over antall registrereringer, krysspeilinger og potensielle meteoritter.",
          icon: "fas fa-chart-bar",
        },
        {
          title: "Dypere innsikt i meteorfenomener",
          subtitle: "Analyse og forståelse av data",
          text: "Dykk ned i vår innsiktsdata for å lære mer om trender i observeringer av meteor over Norge. Vi deler visualiseringer som belyser mønstre og sammenhenger i dataene samlet inn.",
          icon: "fas fa-lightbulb",
        },
        {
          title: "Utforsk meteorene vi har observert",
          subtitle: "Detaljerte observasjoner og analyser",
          text: "Naviger gjennom vår omfattende database med meteorer som er observert av Norsk Meteornettverk.",
          icon: "fas fa-search",
        },
        {
          title: "Visualiser meteoraktivitet over Norge",
          subtitle: "Kartlegging av meteorenes reise",
          text: "Utforsk meteorkart for å se hvor meteorer er observert over Norge, eller for å se hvor meteorene \"kommer i fra\" på himmelen.",
          icon: "fas fa-map-marked-alt",
        },
        {
          title: "Overvåk statusen til vårt kameranettverk",
          subtitle: "Kameraerstasjoner landet rundt",
          text: "Følg med på statusen til vårt kameranettverk i nær sannstid. Her ser du hvor stasjonene befinner seg og deres status. Se også siste snapshot fra kameraene for å følge med på himmelen over Norge.",
          icon: "fas fa-video",
        },
        {
          title: "Last ned data og analyser",
          subtitle: "Tilgang til rådata og forskningsresultater",
          text: "Få tilgang til rådata fra Norsk Meteornettverk. Vi tilbyr datafiler for å fremme deling av kunnskap og samarbeid innen meteorforskning.",
          icon: "fas fa-download",
        }
      ],

    }
  },

  /**
   * Lifecycle hook for fetching initial data when the component is created.
   * @function created
   * @async
   */
  async created() {

    // Initialize default date range if fromDate and toDate are not set
    if (!this.fromDate || !this.toDate) {
      let today = new Date();
      let sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(today.getMonth() - 6);

      this.fromDate = `${sixMonthsAgo.getFullYear()}-${('0' + (sixMonthsAgo.getMonth() + 1)).slice(-2)}-${('0' + sixMonthsAgo.getDate()).slice(-2)}`;
      this.toDate = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;
    }

    // If no selectedStations are present, fetch coordinates
    if (!this.selectedStations.length) {
      console.log("fetchCoordinates in created");
      this.fetchCoordinates({
        fromDate: this.fromDate,
        toDate: this.toDate,
        selectedStations: this.selectedStations,
      });
    }
  },


  methods: {

    ...mapActions('language', ['setLanguage']),
    changeLanguage(newLanguage) {
      this.setLanguage(newLanguage);
    },


    /**
     * This method is called when the route changes and fetched data only if filter values are changed in the query string.
     * @function onRouteChange
     */
    onRouteChange() {

      console.log("on route change, old params: ", this.fromDate, this.toDate, this.selectedStations);
      console.log("on route change, new params: ", this.$route.query.from_date, this.$route.query.to_date, this.$route.query.stations);

      if (!this.initialLoad && (!this.$route.query.from_date || !this.$route.query.to_date)) {
        return;
      }

      const newFromDate = this.$route.query.from_date || '';
      const newToDate = this.$route.query.to_date || '';
      const newSelectedStations = this.$route.query.stations || [];

      // Handle case where old value of selectedStations is a proxy array and new value is an empty array
      const oldSelectedStations = this.selectedStations.slice();
      const paramsChanged = (newFromDate !== this.fromDate) ||
        (newToDate !== this.toDate) ||
        (JSON.stringify(newSelectedStations) !== JSON.stringify(oldSelectedStations));

      if (paramsChanged) {
        this.fromDate = newFromDate;
        this.toDate = newToDate;
        this.selectedStations = newSelectedStations;

        this.fetchCoordinates({
          fromDate: this.fromDate,
          toDate: this.toDate,
          selectedStations: this.selectedStations,
        });

        this.initialLoad = false;
        return;
      }

      if (this.initialLoad) {

        // Set default filter values
        this.setDefaultFilterValues();

        this.fetchCoordinates({
          fromDate: this.fromDate,
          toDate: this.toDate,
          selectedStations: this.selectedStations,
        });

        this.initialLoad = false;
        return;
      }

    },

    /**
     * Vuex action for fetching coordinates.
     * @member {Function} fetchCoordinates
     */
    ...mapActions("dashboard", ["fetchCoordinates"]),

    /**
     * Helper methods for downloading data as a CSV file, stripping date, and generating video URL for meteor observations.
     * @member {Function} downloadCSV
     * @member {Function} stripDate
     * @member {Function} generateVideoUrl
     */
    downloadCSV,
    stripDate,
    generateVideoUrl,

    /**
     * This method updates the query string in the URL based on the filter values.
     * @function updateQueryString
     */
    updateQueryString() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          from_date: this.fromDate,
          to_date: this.toDate,
          stations: this.selectedStations,
        },
      });
    },

    /**
     * Handle meteor click event, fetch the meteor data, and show the modal.
     * @param {number} meteorId - The clicked meteor's ID.
     * @this {VueComponent}
     */
    onMeteorClicked(meteorId) {
      /*  this.getMeteor(meteorId);
       this.isModalVisible = true;
  */
      this.$router.push({
        name: 'MeteorDetails',
        params: {
          id: meteorId
        }
      });
    },

    openMeteorModal(meteorId) {
      console.log("open meteor modal");
      this.isModalLoading = true;
      this.meteorId = meteorId;
      this.currentMeteor = null;
      this.currentMeteorData = null;

      setTimeout(() => {
        this.getMeteor(meteorId)
          .then(() => {

            this.isModalLoading = false;
            this.isModalVisible = true;
          })
          .catch(() => {
            this.isModalLoading = false;
            this.isModalVisible = true;
            setTimeout(() => {
              this.isModalVisible = false;
            }, 2000); // Delay of 2000 milliseconds (2 seconds)
          });
      }, 200); // Delay of 1000 milliseconds (1 second)
    },


    /**
     * Close the modal.
     * @this {VueComponent}
     */
    closeModal() {
      this.isModalVisible = false;
    },

    /**
     * This function retrieves meteor data using MeteorDataService and processes the response
     * to generate video URLs for each observation camera.
     * The video URL is then added to the meteorDataResponse object
     * 
     * @param {string} id - The meteor ID.
     * @returns {Promise<void>} - A promise that resolves when the data has been fetched and processed.
     */
    async getMeteor(id) {
      try {
        const response = await MeteorDataService.get(id);
        const meteorDataResponse = response.data;
        const observationCamData = meteorDataResponse.observation_cam_data;

        observationCamData.forEach((observation) => {
          const date = this.stripDate(observation.video_start);
          const videoUrl = this.generateVideoUrl(meteorDataResponse.datetimetag, observation.cam, date);

          // Add video URL to the meteor detection (camera observation)
          observation.videourl = videoUrl;
        });

        this.currentMeteor = meteorDataResponse;
        this.currentMeteorData = meteorDataResponse;
      } catch (e) {
        console.error(e);
        this.currentMeteor = null;
        this.currentMeteorData = null;
      }
    },

    setDefaultFilterValues() {
      this.fromDate = '2023-01-01';
      this.toDate = '2099-12-31';
      this.selectedStations = [];
    },

    /**
     * Apply the filter and fetch data accordingly.
     * @param {Object} filterData - The filter data to apply.
     * @this {VueComponent}
     */
    applyFilter({
      fromDate,
      toDate,
      selectedStations
    }) {

      if (!this.initialLoad && (fromDate.trim() === '') || ( toDate.trim() === '')) {
        console.log("Invalid or not provided date");
        return;
      }


      this.fromDate = fromDate;
      this.toDate = toDate;
      this.selectedStations = selectedStations;

      console.log("applyFilter");



      this.fetchCoordinates({
        fromDate: this.fromDate,
        toDate: this.toDate,
        selectedStations: this.selectedStations,
      });

      this.initialLoad = false;

    },

  },


  /**
   * Computed properties for getting the latest meteors based on the current scroll position,
   * and mapping state and getters from the Vuex store.
   */
  computed: {
    latestMeteors() {
      const maxMeteorsToShow = Math.ceil(window.innerHeight / 120); // Assumes each meteor card is 120px tall
      const startIndex = this.visibleMeteorIndex;
      const endIndex = startIndex + maxMeteorsToShow;

      // Return the meteors within the visible range
      return this.coordinates.slice(startIndex, endIndex);
    },
    showFilter() {
      const routesToShowFilter = [
        '/statistikk/dashboard/nokkeltall',
        '/statistikk/dashboard/innsikt',
        '/statistikk/dashboard/meteorer',
        '/statistikk/dashboard/meteorkart',
        '/statistikk/dashboard/datanedlasting'
      ];
      return routesToShowFilter.includes(this.$route.path);
    },

    selectedLanguage() {
      return this.$store.getters["language/selectedLanguage"];
    },
    ...mapState("dashboard", ["coordinates", "chartReady", "loading"]),
    ...mapGetters("dashboard", ["coordinates", "chartReady", "loading"]),
    ...mapGetters("language", ["selectedLanguage"]),
  },

};
</script>



<style scoped>
.custom-link {
  color: #f8f9fa;
}

/*
#648FFF
#785EF0
#DC267F
#FE6100
#FFB000
*/

.custom-active-link {
  background-color: #46368e;
  color: #f8f9fa;
}



.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-height: 90%;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: transparent;
}

.modal-content {
  overflow-y: auto;
  flex-grow: 1;
  padding: 20px;
}



.close-modal-btn {
  margin-top: -0.5rem;
  margin-left: auto;
  margin-right: 40px;
  /* Adjust this value to move the button slightly to the left */
  float: right;
  z-index: 99;
  width: 100px;
  padding: 0 10px;
  background-color: #46368e;
}

/* If smaller screens then .modal-container width must have width: 90% */
@media screen and (max-width: 768px) {
  .modal-container {
    width: 90%;
  }
}
</style>