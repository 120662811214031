<template>
  <div class="max-height">
    <Registrations :coordinates="coordinates" :onMeteorClicked="onMeteorClicked" :openMeteorModal="openMeteorModal" />
  </div>
</template>

<script>
import Registrations from "@/components/visualization/Registrations.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Meteorer",
  props: {

    openMeteorModal: {
      type: Function,
      required: true,
    },

  }
  ,
  components: {
    Registrations,
  },
  computed: {
    ...mapGetters("dashboard", ["coordinates"]),
  },
  methods: {
    ...mapActions("dashboard", ["onMeteorClicked"]),
  },
  created() {
    console.log(this.openMeteorModal); 
  },
};
</script>


<style>
.max-height {
  height: calc(100vh - 200px);
  margin-top: -5px;
}

</style>
