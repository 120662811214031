<template>
  <div>
    <div class="row mb-3">
      <div class="col-lg-12">
        <!-- Frequency heatmap -->      
        <FrequencyHeatmap :meteorData="coordinates" />
      </div>
    </div>
  </div>
</template>

<script>
import FrequencyHeatmap from "@/components/visualization/FrequencyHeatmap.vue";
import { mapGetters } from "vuex";

export default {
  name: "Innsikt",
  components: {
    FrequencyHeatmap,
  },
  computed: {
    ...mapGetters("dashboard", ["coordinates"]),
  },
  watch: {
    coordinates(newCoordinates, oldCoordinates) {
      if (newCoordinates !== oldCoordinates) {
        console.log("Coordinates changed");
      }
    },
  },
};
</script>

