<template>
  <div v-if="meteor" class="meteor-data-container">
    <img class="card-image" :src="'https://ildkule.net/data/' +
      meteor.datetimetag.substring(0, 8) +
      '/' +
      meteor.datetimetag.substring(8) +
      '/image.jpg'
      " alt="Meteor thumbnail" @error="onImageError($event, 'https://ildkule.net/img/missing_thumbnail.png')"
      :data-replaced="false" />

    <div style="padding: 0.7rem;">
      <h5>Sammendrag</h5>
      <div class="text-snip-container">
        <span style="text-transform: capitalize;" v-if="meteor.date">
          {{ formatDate(meteor.date) }} </span> (lokal tid i Norge),
        fant det sted en meteordeteksjon<span v-if="!isLocationEmpty"> nær {{ meteor.location }}</span>. Fanget av
        kameraer
        ved stasjonen(e)
        <span v-for="(cam_data, index) in distinctCameras" :key="`station-${index}`">
          {{ capitalizedWord(cam_data.cam.station.station_name) }}<span v-if="index + 1 === distinctCameras.length - 1">
            og
          </span><span v-else-if="index + 1 < distinctCameras.length">, </span>
        </span> <span v-if="!isRadiantEmpty">, kan denne meteoren være en del av meteorsvermen <strong
            style="text-transform: capitalize;">{{ meteor.radiant_shower
            }}</strong></span>.

        <span v-if="meteor.track_startheight">
          Med en observert starthøyde på {{ meteor.track_startheight }} km, falt den gjennom atmosfæren før den sluknet
          i en slutthøyde på {{ meteor.track_endheight }} km. Radianten, eller punktet på himmelen
          hvor meteoren ser ut til å komme fra, hadde koordinater for rektascensjon {{ meteor.radiant_ra }}
          og deklinasjon {{ meteor.radiant_dec }}. Når det gjelder ekliptiske koordinater, hadde radianten en lengdegrad
          på {{ meteor.radiant_ecl_long }} og breddegrad på {{ meteor.radiant_ecl_lat }}. Den falt i målt snitthastighet
          på {{ meteor.track_speed }} km/s og ga trolig en flott opplevelse for himmelobservatører i regionen.
        </span>

        <span v-if="closestStar"> Den nærmeste lyssterke stjernen til radianten er {{ closestStar.ProperName }} i
          stjernebildet {{
            closestStar.Constellation }}.
        </span>

      </div>
      <h5>Tidspunkt</h5>
      <p>{{ meteor.date }}</p>
      <h5>Fallsted</h5>
      <p>{{ meteor.location ? meteor.location : "Ikke fastsatt" }}</p>
      <h5 v-if="meteor.observation_cam_data">Observasjonspunkter</h5>
      <div v-if="meteor.observation_cam_data" class="observation-card-container">
        <div class="card mb-3" v-for="(cam_data, index) in meteor.observation_cam_data" :key="index">
          <div class="card-body">
            <div class="row g-0">
              <div class="col-md-4">
                <h5 class="card-title" style="text-transform: capitalize;">{{ cam_data.cam.cam_name }}</h5>
                <p class="card-text" style="text-transform: capitalize;">{{ cam_data.cam.station.station_name }}</p>
              </div>
              <div class="col-md-8 d-flex align-items-center justify-content-center">
                <img class="fireball-image img-fluid" alt="Ildkulebilde" name="Ildkulebilde" :src="'https://ildkule.net/data/' +
                  meteor.datetimetag.substring(0, 8) +
                  '/' +
                  meteor.datetimetag.substring(8) +
                  '/' +
                  cam_data.cam.station.station_name +
                  '/' +
                  cam_data.cam.cam_name +
                  '/' +
                  'fireball.jpg'
                  " @error="onImageError($event, '/img/ildkule-20121112-mini.1818dfc5.jpg');" />
              </div>
            </div>
            <div class="row g-0">
              <div class="col-md-12">
                <video class="video" style="width: 100%; height: auto" controls>
                  <source v-if="cam_data.videourl" :src="cam_data.videourl" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>


      <h5 v-if="meteor.track_startheight">Meteorens atmosfæriske bane</h5>
      <div v-if="meteor.track_startheight" class="track-height-table-container">
        <p>Starthøyde: {{ meteor.track_startheight }} km Slutthøyde: {{ meteor.track_endheight }} km</p>
      </div>


      <h5 v-if="meteor.radiant_shower">Meteorsverm</h5>
      <div v-if="meteor.radiant_shower" class="meteor-shower-container">
        <p style="text-transform: capitalize;">{{ meteor.radiant_shower }}</p>
      </div>

      <h5 v-if="meteor.radiant_ra || meteor.radiant_dec">Radiantens koordinater</h5>
      <div v-if="meteor.radiant_ra || meteor.radiant_dec" class="radiant-coordinates-container">
        <p>Rektascensjon: {{ meteor.radiant_ra }} Deklinasjon: {{ meteor.radiant_dec }}</p>
      </div>

      <h5 v-if="meteor.track_speed">Hastighet</h5>
      <div v-if="meteor.track_speed" class="meteor-speed-container">
        <p>{{ meteor.track_speed }} km/s</p>
      </div>

      <h5 v-if="meteor.radiant_ecl_long || meteor.radiant_ecl_lat">Radiantens ekliptiske koordinater</h5>
      <div v-if="meteor.radiant_ecl_long || meteor.radiant_ecl_lat" class="radiant-ecl-coordinates-container">
        <p>Lengdegrad: {{ meteor.radiant_ecl_long }} Breddegrad: {{ meteor.radiant_ecl_lat }}</p>
      </div>

      <hr />

      <div v-if="meteor.track_startheight" class="meteor-images-container">
        <img v-for="image in imageList" :key="image.name" class="img-fluid" :src="imageUrl(image.name)" :alt="image.alt"
          @error="onImageError($event, '')" />
      </div>

    </div>
  </div>
</template>

/**
  * MeteorTiny component
  * @module MeteorTiny
  */
<script>
import starsData from '@/components/helpers/stars.js';

export default {
  name: "MeteorTiny",
  props: {
    meteor: { type: Object, default: null },
    meteorData: { type: Object, required: true },
  },
  data() {
    return {
      imageList: [
        { name: "map", alt: "Meteorposisjon markert på kart" },
        { name: "posvstime", alt: "Graf posisjon vs tid" },
        { name: "spd_acc", alt: "Graf hastighet - akselerasjon" },
        { name: "orbit", alt: "Graf meteorbane" },
        { name: "height", alt: "Graf meteorhøyde" },
      ],
    };
  },
  computed: {


    // Check if location is empty
    isLocationEmpty() {
      return !this.meteor.location || !this.meteor.location.trim();
    },
    // Check if radiant is empty
    isRadiantEmpty() {
      return !this.meteor.radiant_shower || !this.meteor.radiant_shower.trim();
    },
    // Capitalize shower name
    capitalizedShower() {
      if (!this.meteor.radiant_shower) return '';
      return this.meteor.radiant_shower
        .split(/\s+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    // Get distinct camera stations
    distinctCameras() {
      const stations = [];
      return this.meteor.observation_cam_data.filter(cam_data => {
        const station = cam_data.cam.station.station_name.toLowerCase();
        if (!stations.includes(station)) {
          stations.push(station);
          return true;
        }
        return false;
      });
    },
    // Find closest star to meteor radiant
    closestStar() {
      const namedStarsData = starsData.filter(
        star => star.ProperName && star.ProperName.trim() !== ''
      );
      const stars = namedStarsData
        .map(star => ({
          ProperName: star.ProperName,
          Constellation: star.BayerFlamsteed.slice(-3),
          RA: parseFloat(star.RA) * 15,
          Dec: parseFloat(star.Dec),
          mag: parseFloat(star.Mag),
          coordSystem: star.CoordSystem,
        }))
        .sort((a, b) => a.mag - b.mag)
        .slice(0, 75);

      if (this.meteor.radiant_ra && this.meteor.radiant_dec) {
        const radiantRa = parseFloat(this.meteor.radiant_ra);
        const radiantDec = parseFloat(this.meteor.radiant_dec);

        let closestStar = null;
        let closestDistance = Infinity;

        for (const star of stars) {
          const starRa = star.RA;
          const starDec = star.Dec;
          let raDiff1 = Math.abs(starRa - radiantRa);
          let raDiff2 = Math.abs(starRa - radiantRa + 180);
          if (raDiff1 > 180) raDiff1 = 360 - raDiff1;
          if (raDiff2 > 180) raDiff2 = 360 - raDiff2;
          const raDiff = Math.min(raDiff1, raDiff2);
          const decDiff = Math.abs(starDec - radiantDec);
          const distance = Math.sqrt(raDiff ** 2 + decDiff ** 2);
          if (distance < closestDistance) {
            closestStar = star;
            closestDistance = distance;
          }
        }
        return closestStar;
      }
      return null;
    },
  },
  methods: {

    onImageError(event) {
      // Create a new Font Awesome icon element
      const icon = document.createElement('i');
      icon.className = 'fas fa-image'; // Choose the icon you want to use, in this case 'fa-image'

      // Replace the image element with the icon element
      event.target.replaceWith(icon);
      event.target.onerror = null; // Remove the error event to avoid infinite loop
    },


    // Generate img url for the template
    imageUrl(imageName) {
      return (
        "https://ildkule.net/data/" +
        this.meteor.datetimetag.substring(0, 8) +
        "/" +
        this.meteor.datetimetag.substring(8) +
        `/${imageName}.jpg`
      );
    },


    // Capitalize word
    capitalizedWord(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    // Format date
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const localDate = date.toLocaleDateString('nb-NO', options);
      const localTime = date.toLocaleTimeString('nb-NO', { timeZone: 'Europe/Oslo' });
      return `${localDate}, klokken ${localTime}`;
    },
  },
};
</script>


<style scoped>
.meteor-data-container {
  padding: 0px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scrollable-modal {
  max-height: 90vh;
  overflow-y: auto;
}

.scrollable-modal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 13px;
}


.card-image {
  display: block;
  margin: 0 auto 5px;
  max-width: 100%;
}

.observation-table-container,
.track-height-table-container {
  margin-bottom: 1rem;
}


.video {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.meteor-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.meteor-images-container img {
  max-width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-snip-container {
  margin-bottom: 1rem;
  text-align: justify;
}


.observation-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.card {
  width: 100%;
  max-width: 25rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.fireball-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>