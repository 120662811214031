<template>
    <div class="detections">

        <div class="d-flex">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="toggleXAxis" @change="toggleImages">
                <label class="form-check-label" for="toggleXAxis">Vis bilder</label>
            </div>

            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="toggleAI" v-model="ildkuleAI">
                <label class="form-check-label" for="toggleAI">Ildkule AI</label>
            </div>
        </div>

        <div class="row mb-3 scrollable" style="max-height: calc(90vh - 160px); overflow-y: auto; " id="latestMeteorsDiv"
            ref="latestMeteorsDiv" @scroll="handleScroll" @touchmove="handleScroll">
            <div class="col-lg-3 col-md-4 col-sm-6"
                v-for="(meteor, index) in filteredCoordinates.slice(0, displayedItemCount)" :key="index">
                <div class="card mx-auto chart-gradient clickable-card fadeIn" @click="onMeteorClicked(meteor.id)">
                    <img v-if="showImages" class="img-fluid"
                        :src="'https://ildkule.net/img/' + meteor.datetimetag + '_thumbnail.jpg'" alt="Meteor thumbnail"
                        @error="onImageError($event, 'https://ildkule.net/img/missing_thumbnail.png')"
                        :data-replaced="false" />
                    <div class="card-body">
                        <h6 class="card-title">Registering av {{ meteor.StationCam }}</h6>
                        <p class="card-text mb-1">Tidspunkt (UTC): {{ meteor.date }}</p>
                        <p class="card-text mb-1 small">Meteorsannsynlighet: {{ (meteor.MeteorScoreHighest * 100).toFixed(2)
                        }}%
                            (automatisk
                            beregning)</p>
                        <div class="d-flex justify-content-between align-items-center">
                            <a href="#" class="btn btn-secondary btn-sm">Detaljer</a>
                            <span v-if="meteor.radiant_shower" class="badge"
                                :style="{ backgroundColor: getTrackSpeedColor(meteor.track_speed) }">
                                {{ meteor.radiant_shower }}
                            </span>
                            <span v-else-if="meteor.ProperTriangulation === '1'" class="badge"
                                style="background-color: grey;">
                                Krysspeilet
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import missingImg from '@/assets/missing_thumbnail.png';

export default {
    name: 'MeteorRegistrations',

    props: {
        coordinates: {
            type: Array,
            required: true,
        },

        openMeteorModal: {
            type: Function,
            required: true
        }

    },

    data() {
        return {
            missingImg,
            displayedItemCount: 40,
            showImages: false,

            ildkuleAI: true,

        };
    },

    computed: {
        filteredCoordinates() {
            if (this.ildkuleAI) {
                return this.coordinates.filter(meteor => (meteor.MeteorScoreHighest >= 0.7 &&
                    /*meteor.datetimetag does not contain "wrong"*/
                    meteor.datetimetag.indexOf("wrong") === -1) || meteor.ProperTriangulation == "1");

            }
            return this.coordinates;
        }
    },

    created() {
        console.log(this.openMeteorModal); // Should also log the function
    },

    methods: {


        getTrackSpeedColor(speed) {
            const palette = [
                '#648FFF', // 0-18 km/s
                '#785EF0', // 18-25 km/s
                '#DC267F', // 25-34 km/s
                '#FE6100', // 34-55 km/s
                '#FFB000', // 55+ km/s
            ];

            let index;
            if (speed < 18) {
                index = 0;
            } else if (speed < 25) {
                index = 1;
            } else if (speed < 34) {
                index = 2;
            } else if (speed < 55) {
                index = 3;
            } else {
                index = 4;
            }

            return palette[index];
        },
        onImageError(event, fallbackUrl) {
            event.target.src = fallbackUrl;
        },

        toggleIldkuleAI() {
            this.ildkuleAI = !this.ildkuleAI;
        },

        onMeteorClicked(meteorId) {
            /* this.getMeteor(meteorId);
            this.isModalVisible = true; */

            this.openMeteorModal(meteorId);

            /*   this.$router.push({
                  name: 'MeteorDetails',
                  params: {
                      id: meteorId
                  }
              }); */
        },

        /**
         * Toggle the visibility of meteor images.
         * @this {VueComponent}
         */
        toggleImages() {
            this.showImages = !this.showImages;
        },

        /**
         * Handle the scroll event and load more items if needed.
         * @param {Event} event - The scroll event.
         * @this {VueComponent}
         */
        handleScroll(event) {
            console.log("Scrolling");
  

            const div = event.target;
            console.log(div.scrollTop + div.offsetHeight);
            console.log(div.scrollHeight);
            if (div.scrollTop + div.offsetHeight+20 >= div.scrollHeight) {
                this.displayedItemCount += 10; // Increase the number of displayed items
            }
        },

    }

}
</script>

<style scoped>
.clickable-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.clickable-card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detections {
    color: white;
}

.fadeIn {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
