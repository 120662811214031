const stations = [
    {
        displayValue: 'Eiscat',
        apiValue: 'Eiscat'
    },
    {
        displayValue: 'Gaustatoppen',
        apiValue: 'Gaustatoppen'
    },
    {
        displayValue: 'Gran',
        apiValue: 'Gran'
    },
    {
        displayValue: 'Harestua',
        apiValue: 'Harestua'
    },
    {
        displayValue: 'Kristiansand',
        apiValue: 'Kristiansand'
    },
    {
        displayValue: 'Larvik',
        apiValue: 'Larvik'
    },
    {
        displayValue: 'Ørsta',
        apiValue: 'Orsta'
    },
    {
        displayValue: 'Skibotn',
        apiValue: 'Skibotn'
    },
    {
        displayValue: 'Solobservatoriet',
        apiValue: 'Solobservatoriet'
    },
    {
        displayValue: 'Sørreisa',
        apiValue: 'Sorreisa'
    },
    {
        displayValue: 'Trondheim',
        apiValue: 'Trondheim'
    },
    {
        displayValue: 'Voksenlia',
        apiValue: 'Voksenlia'
    },
]


export default stations;
  