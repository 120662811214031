<template>
  <div class="my-2 p-1">
    <div class="m-0 p-0">
      <div class="filter-container d-flex justify-content-center align-items-center h-100  ">
        <div class="date-inputs">
          <div>
            <label style="color:white">Fra&nbsp;</label>
            <input type="date" v-model="internalFromDate">
          </div>
          <div>
            <label style="color:white">Til&nbsp;</label>
            <input type="date" v-model="internalToDate">
          </div>
        </div>
        <div class="station-dropdown">
          <div class="dropdown" @click.self="toggleDropdown">
            <div class="selected-stations">
              <span v-if="!selectedStations.length">Kamerastasjoner</span>
              <span v-else>{{ selectedStations.join(', ') }}</span>
            </div>
            <div class="toggle-icon" @click.stop="toggleDropdown">
              <div class="toggle-arrow" :class="{ 'arrow-up': dropdownVisible, 'arrow-down': !dropdownVisible }"></div>
            </div>
            <div class="dropdown-menu" v-if="dropdownVisible">
              <div v-for="station in stations" :key="station.apiValue" @click.stop="toggleStation(station.apiValue)">
                <input type="checkbox" :value="station.apiValue" :checked="selectedStations.includes(station.apiValue)">
                {{ station.displayValue }}
              </div>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button @click="applyFilter">Filtrer</button>
          <button @click="clearFilters">Nullstill</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/**
 * FilterComponent is a Vue component that provides a set of filters for selecting
 * date ranges and meteor camera stations. The component emits an event with the filter values when
 * the filters are applied or reset.
 * @module FilterComponent
 */
export default {
  name: 'FilterComponent',
  props: {
    stations: {
      type: Array,
      default: () => []
    },
    fromDate: String,
    toDate: String,
  },
  data() {
    return {
      internalFromDate: '',
      internalToDate: '',
      selectedStations: [],
      dropdownVisible: false,
    };
  },
  mounted() {
    this.resetDatesToLast3Months();
    this.applyFilter();
  },

  methods: {
    /**
     * Emits an event with the current filter values.
     */
    applyFilter() {
      this.$emit('filter-applied', {
        fromDate: this.internalFromDate,
        toDate: this.internalToDate,
        selectedStations: this.selectedStations,
      });
    },
    /**
     * Toggles the visibility of the station dropdown.
     */
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      if (this.dropdownVisible) {
        document.addEventListener('click', this.documentClickHandler);
      } else {
        document.removeEventListener('click', this.documentClickHandler);
      }
    },
    /**
     * Handles clicks outside the dropdown and closes it.
     */
    documentClickHandler(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownVisible = false;
        document.removeEventListener('click', this.documentClickHandler);
      }
    },
    /**
     * Toggles the selection of a station in the station filter.
     * @param {string} station - The station to be toggled.
     */
    toggleStation(station) {
      if (this.selectedStations.includes(station)) {
        this.selectedStations = this.selectedStations.filter((s) => s !== station);
      } else {
        this.selectedStations.push(station);
      }
    },
    /**
     * Clears all the selected filters and resets the date range to the last 3 months.
     */
    clearFilters() {
      this.selectedStations = [];
      this.resetDatesToLast3Months();
      this.applyFilter();
    },
    /**
     * Gets the date range for the last 3 months.
     * @returns {Object} - An object with fromDate and toDate properties.
     */
    getLast3Months() {
      const currentDate = new Date();
      const threeMonthsAgo = new Date(currentDate);
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
      return {
        fromDate: threeMonthsAgo.toISOString().split('T')[0],
        toDate: currentDate.toISOString().split('T')[0],
      };
    },
    /**
     * Resets the date range to the last 3 months.
     */
    resetDatesToLast3Months() {
      const {
        fromDate,
        toDate
      } = this.getLast3Months();
      this.internalFromDate = fromDate;
      this.internalToDate = toDate;
    },
  },
};
</script>

<style scoped>
.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.date-input
 {
  color: white;
}

.date-inputs,
.station-dropdown {
  display: flex;
}

.date-inputs>div,
.station-dropdown>div {
  margin-right: 0.5rem;
}

.date-inputs label,
.station-dropdown label {
  display: inline-block;
}

.date-inputs input[type="date"],
.station-dropdown .selected-stations {
  height: 2rem;
  line-height: 2rem;
  width: 8rem;
}

.station-dropdown {
  margin-right: 0.5rem;
}

.button-container {
  display: flex;
}

button {
  margin-right: 0.5rem;
}

button,
.close-dropdown {
  background-color: #46368e;
  ;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
.close-dropdown:hover {
  background-color: #c2c2c2;
}

.dropdown {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  width: 150px;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  z-index: 9;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 25rem;
  overflow-y: auto;
  width: 100%;
}

.dropdown-menu div {
  display: flex;
  align-items: center;
}

.dropdown-menu div:hover {
  background-color: #f2f2f2;
}

.selected-stations {
  min-width: 100px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toggle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  cursor: pointer;
}

.toggle-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #bebebe;
  pointer-events: none;
}

.arrow-up {
  border-top: none;
  border-bottom: 5px solid #bebebe;
}

/* Media queries for responsive design */
@media (max-width: 480px) {
  .filter-container {
    justify-content: space-between;
  }

  .date-inputs>div {
    margin-right: 0.5rem;
  }

  .station-dropdown {
    margin-right: 0.5rem;
  }

  .button-container {
    display: flex;
  }

  button {
    margin-right: 0.5rem;
  }
}
</style>
