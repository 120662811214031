<template>
    <div class="card chart-gradient" style="height:100%; margin-top: -5px;">
        <div class="card-header" style="color:white;">Meteorfrekvens</div>
        <div class="card-body">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="toggleXAxis" @change="toggleXAxis">
                <label class="form-check-label" style="color:white;" for="toggleXAxis">Vis per døgnets time</label>
            </div>
            <div class="heatmap" ref="heatmap"></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from "apexcharts";

export default {
    name: "Heatmap",
    props: {
        meteorData: {
            type: Array,
            required: true,
        },
    },
    data() {

        return {

            xAxisMode: "days",


            chart: null,
            chartOptions: {
                theme: {
                    mode: 'dark',
                },

                stroke: {
                    show: true,


                    width: 1,

                },

                chart: {
                    type: "heatmap",
                    width: "100%",
                    height: 200,
                    background: 'transparent',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        },
                    },


                },

                dataLabels: {
                    show: false,
                    enabled: false,
                    formatter: function (val) {
                        return val || "N/A";
                    },
                },
                colors: ['#648FFF'],

                plotOptions: {
                    heatmap: {

                        enableShades: false,
                        distributed: false,
                        colorScale: {

                        }
                    },
                },

                xaxis: {

                    tickPlacement: 'between',
                    type: "numeric",

                    labels: {


                        style: {
                            fontSize: "12px",
                        },

                    },
                },
                yaxis: {
                    type: "category",
                    labels: {
                        style: {
                            fontSize: "12px",
                        },
                    },
                    categories: [],
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false,
                        formatter: function (val) {
                            return `Dag ${val}`;
                        },
                    },
                    y: {
                        show: false,
                        formatter: function (val) {
                            if (val == null) {
                                return "N/A";
                            }
                            if (typeof val !== "string") {
                                return val;
                            }
                            const [year, month] = val.split("-");
                            return `${year}-${month}`;
                        },
                    },
                    z: {
                        show: false,
                        formatter: function (val) {
                            return val.y || "N/A";
                        },
                    },
                    marker: {
                        show: false,
                    },
                },
            },
            chartSeries: [],
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.loadChartData();
        });
    },

    watch: {
        xAxisMode() {
            this.loadChartData();
        },
    },

    methods: {

        toggleXAxis() {
            this.xAxisMode = this.xAxisMode === "days" ? "hours" : "days";
        },

        /**
         * Converts hex color to RGB format.
         *
         * @param {string} hex - The hex color to convert.
         * @returns {Array<number>} An array of RGB values.
         */
        hexToRgb(hex) {
            const bigint = parseInt(hex.slice(1), 16);
            return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
        },

        /**
         * Converts RGB color to hex format.
         *
         * @param {number} r - The red value.
         * @param {number} g - The green value.
         * @param {number} b - The blue value.
         * @returns {string} The hex color string.
         */
        rgbToHex(r, g, b) {
            return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        },

        /**
         * Blends two colors using the provided ratio.
         *
         * @param {string} color1 - The first color to blend.
         * @param {string} color2 - The second color to blend.
         * @param {number} ratio - The ratio of blending, between 0 and 1.
         * @returns {string} The blended color in hex format.
         */
        blendColors(color1, color2, ratio) {
            const color1Rgb = this.hexToRgb(color1);
            const color2Rgb = this.hexToRgb(color2);

            const blendedRgb = color1Rgb.map((channel, index) => {
                return Math.round(channel * (1 - ratio) + color2Rgb[index] * ratio);
            });

            return this.rgbToHex(...blendedRgb);
        },

        /**
         * Generates logarithmic color ranges based on the provided colors and max value.
         *
         * @param {Array<string>} colors - The array of colors to use for the ranges.
         * @param {number} maxValue - The maximum value for the color ranges.
         * @param {number} [stepsPerSegment=10] - The number of steps per segment.
         * @returns {Array<Object>} An array of color ranges.
         */
        generateLogarithmicColorRanges(colors, maxValue, stepsPerSegment = 10) {
            const logMaxValue = Math.log(maxValue);
            const numColors = colors.length - 1;
            const ranges = [];

            for (let i = 0; i < numColors; i++) {
                const fromValue = i === 0 ? 0 : Math.round(Math.exp((i * logMaxValue) / numColors));
                const toValue = Math.round(Math.exp(((i + 1) * logMaxValue) / numColors));
                const segmentStepSize = (toValue - fromValue) / stepsPerSegment;

                for (let j = 0; j < stepsPerSegment; j++) {
                    const from = Math.round(fromValue + j * segmentStepSize);
                    const to = Math.round(fromValue + (j + 1) * segmentStepSize);
                    const ratio = j / stepsPerSegment;
                    const color = this.blendColors(colors[i], colors[i + 1], ratio);

                    ranges.push({
                        from,
                        to,
                        color,
                    });
                }
            }

            return ranges;
        },

        loadChartData() {

            if (this.chart) {
                this.chart.destroy();
            }

            // Filter the data based on the point.SourceBadDetection property
            const filteredData = this.meteorData.filter((point) => point.SourceBadDetection === '0');

            // Modify the tooltip formatter based on the xAxisMode
            this.chartOptions.tooltip.x.formatter = this.xAxisMode === "days" ?
                function (val) {
                    return `Dag ${val}`;
                } :
                function (val) {


                    return `Time ${val}`;
                };
            // Modify the x-axis label formatter based on the xAxisMode
            this.chartOptions.xaxis.labels.formatter = this.xAxisMode === "days" ?
                function (val) {
                    return `Dag ${Math.floor(val)}`;
                } :
                function (val) {
                    return `Time ${Math.floor(val)}`;
                };



            if (this.xAxisMode === "days") {
                this.loadChartDataDays(filteredData);
            } else {
                this.loadChartDataHours(filteredData);
            }

            this.renderChart();
        },

        renderChart() {
            // Find the maximum value
            let maxValue = 0;
            for (let i = 0; i < this.chartSeries.length; i++) {
                const dataPoints = this.chartSeries[i].data;
                for (let j = 0; j < dataPoints.length; j++) {
                    const cellValue = dataPoints[j].y;
                    if (cellValue > maxValue) {
                        maxValue = cellValue;
                    }
                }
            }

            const colors = ['#00164d', '#80a4ff', "#ffdf80"];
            const colorScaleRanges = this.generateLogarithmicColorRanges(colors, maxValue, 8);

            this.chartOptions.plotOptions.heatmap.colorScale.ranges = colorScaleRanges;

            // Determine the number of rows
            const numRows = this.chartSeries.length;

            // Set the height based on the number of rows
            const heightPerRow = 40; // pixels
            const minHeight = 200; // pixels
            const maxHeight = 1000; // pixels

            let totalHeight = numRows * heightPerRow;
            totalHeight = Math.max(totalHeight, minHeight); // Ensure it's not less than the minimum height
            totalHeight = Math.min(totalHeight, maxHeight); // Ensure it's not more than the maximum height

            this.chartOptions.chart.height = totalHeight;

            this.chartOptions.series = this.chartSeries;
            this.chart = new ApexCharts(this.$refs.heatmap, this.chartOptions);
            this.chart.render();
        },



        loadChartDataDays(filteredData) {

            // Get the unique year-month combinations from the filtered data
            const yearMonths = [...new Set(filteredData.map((item) => item.date.slice(0, 7)))];

            const chartData = yearMonths.map((yearMonth) => {
                const [year, month] = yearMonth.split("-");
                const daysInMonth = new Date(year, month, 0).getDate();
                const length = this.xAxisMode === "days" ? daysInMonth : 24;

                const data = Array.from({
                    length
                }, (_, i) => ({
                    x: i +1,
                    y: 0
                }));

                filteredData.forEach((item) => {
                    const date = new Date(item.date);
                    const dataYear = date.getFullYear();
                    const dataMonth = date.getMonth() + 1;
                    const dataDay = date.getDate();
                    const dataKey = `${dataYear}-${dataMonth.toString().padStart(2, "0")}`;

                    if (dataKey === yearMonth) {
                        const index = dataDay - 1;
                        data[index] = {
                            x: dataDay,
                            y: data[index].y + 1
                        };
                    }
                });

                return {
                    name: yearMonth,
                    data,
                };
            });

            // Sort the chartData array alphabetically by name
            chartData.sort((a, b) => a.name.localeCompare(b.name));
            this.chartSeries = chartData.reverse(); // Reverse the order of chart data

            // Set the reversed sorted categories to the y-axis configuration
            this.chartOptions.yaxis.categories = yearMonths.reverse(); // Reverse the order of y-axis categories

        },

        loadChartDataHours(filteredData) {
            // Get the unique year-month combinations from the filtered data
            const yearMonths = [...new Set(filteredData.map((item) => item.date.slice(0, 7)))];

            const chartData = yearMonths.map((yearMonth) => {
                const [year, month] = yearMonth.split("-");
                const daysInMonth = new Date(year, month, 0).getDate();
                const length = this.xAxisMode === "days" ? daysInMonth : 24;

                const data = Array.from({ length }, (_, i) => ({
                    x: i,
                    y: 0
                }));

                filteredData.forEach((item) => {
                    const date = new Date(item.date);
                    const dataYear = date.getFullYear();
                    const dataMonth = date.getMonth() + 1;
                    const dataDayOrHour = this.xAxisMode === "days" ? date.getDate() : date.getHours();
                    const dataKey = `${dataYear}-${dataMonth.toString().padStart(2, "0")}`;

                    if (dataKey === yearMonth) {
                        const index = dataDayOrHour ;
                        data[index] = {
                            x: dataDayOrHour,
                            y: (data[index] ? data[index].y : 0) + 1
                        };
                    }
                });

                return {
                    name: yearMonth,
                    data,
                };
            });

            // Sort the chartData array 
            chartData.sort((a, b) => a.name.localeCompare(b.name))
            this.chartSeries = chartData.reverse(); // Reverse the order of chart data

            // Set the reversed sorted categories to the y-axis configuration
            this.chartOptions.yaxis.categories = yearMonths.reverse(); // Reverse the order of y-axis categories
        }



    },

};
</script>

<style>
.apexcharts-tooltip span {
    color: #ffffff;
}

<style scoped>.apexcharts-tooltip span {
    color: #ffffff;
}
</style>
```
