<template>
    <div class="d-flex justify-content-center">
        <!-- Loading spinner -->
        <div v-if="isLoading" class="d-flex justify-content-center">
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">{{ text.loadingText }}</span>
            </div>
        </div>
        <!-- Main content -->
        <main v-else>
            <!-- Card component -->
            <div class="card chart-gradient fadeIn" style="color:white; transition: 0.5s; max-width: 1000px">
                <!-- Card header -->

                <header class="card-header d-flex align-items-center justify-content-between">
                    <!-- Header text -->
                    <div>{{ translations[selectedLanguage]['subtitle'] }}</div>

                </header>
                <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 mb-3">
                            <!-- Subtitle text in italics -->
                            <p><i>{{ translations[selectedLanguage]['italicText'] }}</i></p>
                        </div>
                    </div>
                    <!-- Iterate over stations -->
                    <div class="row">
                        <div class="col-lg-4" v-for="station in stationsLastSeen" :key="station.station"
                            :id="'station-' + station.station">
                            <div class="mb-3">
                                <div class="d-flex align-items-center station-info"
                                    :aria-expanded="station.showCameraInfo ? 'true' : 'false'">
                                    <!-- Button to toggle camera info visibility -->
                                    <button class="btn btn-sm btn-outline-primary me-2"
                                        @click="station.showCameraInfo = !station.showCameraInfo"
                                        :aria-controls="'cameras-' + station.station"
                                        :aria-label="station.showCameraInfo ? translations[selectedLanguage]['collapseCameraInfoText'] : translations[selectedLanguage]['expandCameraInfoText']">
                                        <i class="fas" :class="{
                                            'fa-chevron-down': station.showCameraInfo,
                                            'fa-chevron-up': !station.showCameraInfo
                                        }"></i>
                                    </button>
                                    <!-- Station name -->
                                    <h6 class="mb-0 flex-grow-1">{{ station.stationName }}</h6>
                                    <!-- Traffic light status and time since last seen for station -->
                                    <span :class="stationTrafficLightStatus(station.cameras)">
                                        {{
                                            lastSeen(getLastSeenTime(station)).days +
                                            ' ' +
                                            translations[selectedLanguage]['dayText'] +
                                            ', ' +
                                            lastSeen(getLastSeenTime(station)).hours +
                                            ' ' +
                                            translations[selectedLanguage]['hourText'] +
                                            ' ' +
                                            translations[selectedLanguage]['agoText']
                                        }}
                                    </span>
                                </div>
                                <!-- Camera info -->
                                <div v-if="station.showCameraInfo" class="station-camera-info" style="max-width: 350px"
                                    :id="'cameras-' + station.station" :aria-labelledby="'station-' + station.station">
                                    <ul class="list-unstyled">
                                        <!-- Iterate over cameras -->
                                        <li class="m-2" v-for="camera in station.cameras"
                                            :key="`${station.station}-${camera.name}`"
                                            :id="'camera-' + station.station + '-' + camera.name">
                                            <div class="d-flex align-items-center">
                                                <!-- Padding to align with station name -->
                                                <div class="camera-info-padding"></div>
                                                <!-- Camera name -->
                                                <span class="flex-grow-1">
                                                    <a :href="camera.imgService" target="_blank" class="camera-name-link"
                                                        @click.prevent="navigate(camera.imgService)"
                                                        :aria-describedby="'camera-' + station.station + '-' + camera.name">
                                                        {{ camera.name }}
                                                    </a>
                                                    <div class="image-preview" v-if="camera.imgService && !isMobile">
                                                        <img :src="camera.imgService" @error="handleImageError"
                                                            alt="Image preview" style="width:200px;">
                                                    </div>
                                                    <div class="image-preview" v-else-if="!isMobile">
                                                        <img :src="missingImageIcon" alt="Image not available"
                                                            style="width:200px;">
                                                    </div>
                                                </span>


                                                <div>
                                                    <template v-if="camera.connected">
                                                        <!-- Display elapsed time since last seen for camera -->
                                                        <span v-if="camera.lastSeen"
                                                            :class="cameraTrafficLightStatus(camera.lastSeen)">
                                                            {{ translations[selectedLanguage]['lastSeenText'] + ' ' +
                                                                lastSeen(new Date(camera.lastSeen).getTime()).days + ' ' +
                                                                translations[selectedLanguage]['dayText'] +
                                                                ',' +
                                                                lastSeen(new Date(camera.lastSeen).getTime()).hours + ' ' +
                                                                translations[selectedLanguage]['hourText'] +
                                                                ' ' + translations[selectedLanguage]['agoText'] }}
                                                        </span>
                                                    </template>
                                                    <span v-else :class="trafficLightStatus(camera.lastSeen)"
                                                        :aria-label="translations[selectedLanguage]['noSignalText']">
                                                        {{ translations[selectedLanguage]['noSignalText'] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div>
</template>

<script>
import {
    elapsedTime,
    getLastSeenTime,
    timeSinceLastSeen,
    cameraTrafficLightStatus,
    stationTrafficLightStatus,
    getLastSeenCameraStations,
    trafficLightStatus,
    lastSeen
} from "../../components/helpers/helpers.js";

import missingImageIcon from '@/assets/missing_thumbnail.png';

import {
    mapGetters,
} from "vuex";

export default {
    name: "CameraStations",
    data() {
        return {
            isMobile: false,

            missingImageIcon,
            stationsLastSeen: [],
            isLoading: true,
            text: {},
            language: this.$store.state.language.selectedLanguage,
            translations: {
                'en': {
                    'subtitle': "Camera Stations",
                    'italicText': "The 'last seen' time is based on the timestamps of generated snapshots",
                    'lastSeenText': 'last detected',
                    'noSignalText': 'no recent activity detected',
                    'expandCameraInfoText': "Expand camera info",
                    'collapseCameraInfoText': "Collapse camera info",
                    'dayText': 'days',
                    'hourText': 'hours',
                    'agoText': 'ago',
                },
                'no': {
                    'subtitle': "Kamerastasjoner",
                    'italicText': "Sist sett tid er basert på genererte \"snapshots\" ",
                    'lastSeenText': 'ble sist oppdaget',
                    'noSignalText': 'ingen nylig aktivitet oppdaget',
                    'expandCameraInfoText': "Vis kamerainformasjon",
                    'collapseCameraInfoText': "Skjul kamerainformasjon",
                    'dayText': 'dager',
                    'hourText': 'timer',
                    'agoText': 'siden',
                },
            },

        };
    },
    mounted() {
        this.checkIfMobileDevice();
        // listen for resize event if user resizes the window
        window.addEventListener('resize', this.checkIfMobileDevice);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.checkIfMobileDevice);
    },
    methods: {

        navigate(url) {
            window.open(url, '_blank');
        },


        checkIfMobileDevice() {
            // This will set isMobile to true if the max screen size is less than 800 pixels or if the device supports touch events.
            this.isMobile = window.innerWidth <= 800 || 'ontouchstart' in window.document.documentElement;
        },

        handleImageError(e) {
            if (e.target.src !== this.missingImageIcon) {
                e.target.onerror = null;
                e.target.src = this.missingImageIcon;
            }
        },


        toggleCameraInfo(station) {
            station.showCameraInfo = !station.showCameraInfo;
        },

        async loadData() {
            this.isLoading = true;
            const stations = await getLastSeenCameraStations();
            console.log('Loaded stations:', stations);
            this.stationsLastSeen = stations.map((station) => ({
                ...station,
                showCameraInfo: false,
            }));
            this.isLoading = false;
        },

        elapsedTime,
        getLastSeenTime,
        timeSinceLastSeen,
        cameraTrafficLightStatus,
        stationTrafficLightStatus,
        getLastSeenCameraStations,
        trafficLightStatus,
        lastSeen,
    },

    created() {

        this.loadData();
    },
    computed: {
        selectedLanguage() {
            return this.$store.getters["language/selectedLanguage"];
        },
        ...mapGetters("language", ["selectedLanguage"]),
    },
};
</script>

<style scoped>
.badge.badge-primary-custom {
    color: #000;
    background-color: #648FFF;
}

.badge.badge-warning-custom {
    color: #000;
    background-color: #FFB000;
}

.badge.badge-danger-custom {
    color: #fff;
    background-color: #DC267F;
}

.station-info,
.station-camera-info {
    max-width: 350px;
}

.camera-name {
    display: inline-block;
    width: 200px;
    text-align: left;
}

.station-info {
    justify-content: space-between;
}

.camera-name-link {
    color: inherit;
    text-decoration: underline;
}

.camera-name-link:hover {
    text-decoration: underline;
    color: #007bff;
}

.fadeIn {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.image-preview {
    display: none;
    position: absolute;
}

.camera-name-link:hover+.image-preview {
    display: block;
}
</style>

