<template>
  <div>
    <camera-stations></camera-stations>
  </div>
</template>

<script>

import CameraStations from '@/components/visualization/CameraStations.vue';

export default {
  components: {
    CameraStations
  },
  name: 'Kameranettverk'
};
</script>
